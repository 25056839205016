import { createStore } from "vuex";
import { auth } from "./auth.modules";
import { myEnt } from "./myEnt.modules";
import { tks } from "./tks.modules";
import { program } from "./program.modules";
import { hi } from "./hi.modules";
import { getProfileUser } from '@/authService';

const store = createStore({
  modules: {
    auth,
    myEnt,
    tks,
    program,
    hi
  },
  async state () {
    return {
      user: await getProfileUser(),
    }
  },
});

export default store;