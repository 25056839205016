// import Vue from "vue";
// import * as Vue from "vue";
import { app } from '@/main.js'
import axios from "axios";
// import VueAxios from "vue-axios";
import { API_URL, BEARER_TOKEN_MASMED } from "./config";

const ApiService = {
  init() {
    // Vue.use(VueAxios, axios);

    const axiosBase = axios.create({
        baseURL: API_URL,
        // headers: {
        //   'Authorization' : `Bearer ${JwtService.getToken()}`,
        // },
    });
    // Vue.axios.defaults.baseURL = API_URL;
    return axiosBase;
  },

  setHeader() {
    app.axiosMasmed.defaults.headers.common[
      "Authorization"
    ] = 'Bearer '+BEARER_TOKEN_MASMED;
    app.axiosMasmed.defaults.baseURL = API_URL;
  },

  setHeaderMultipart() {
    app.axiosMasmed.defaults.headers.common["Content-Type"] = "multipart/form-data";
  },

  query(resource, params) {
    return app.axiosMasmed.get(resource, params).catch(error => {
      throw new Error(`[RWV] ApiService ${error}`);
    });
  },

  get(resource, slug = "") {
    return app.axiosMasmed.get(`${resource}/${slug}`).catch(error => {
      // throw new Error(`[RWV] ApiService ${error}`);
      return error;
    });
  },

  postFile(resource, params,) {
    return app.axiosMasmed.post(`${resource}`, params, {responseType: 'arraybuffer'});
  },

  post(resource, params) {
    return app.axiosMasmed.post(`${resource}`, params);
  },

  update(resource, slug, params) {
    return app.axiosMasmed.put(`${resource}/${slug}`, params);
  },

  put(resource, params) {
    return app.axiosMasmed.put(`${resource}`, params);
  },

  delete(resource) {
    return app.axiosMasmed.delete(resource).catch(error => {
      throw new Error(`[RWV] ApiService ${error}`);
    });
  }
};

export default ApiService;

export const TagsService = {
  get() {
    return ApiService.get("tags");
  }
};

export const CommentsService = {
  get(slug) {
    if (typeof slug !== "string") {
      throw new Error(
        "[RWV] CommentsService.get() article slug required to fetch comments"
      );
    }
    return ApiService.get("articles", `${slug}/comments`);
  },

  post(slug, payload) {
    return ApiService.post(`articles/${slug}/comments`, {
      comment: { body: payload }
    });
  },

  destroy(slug, commentId) {
    return ApiService.delete(`articles/${slug}/comments/${commentId}`);
  }
};