import ApiHIService from "../common/api.hi.service";
import { doc, getDoc} from "firebase/firestore";
import { db } from "../firebase/index";
import HIService from "../common/hi.service";

export const hi = {
  namespaced: true,
  state: null,
  actions: {
    getQuestion() {
		return new Promise((resolve, reject) => {
			getDoc(doc(db, "happinessIndex", moment().format("YYYYMMDD"))).then(docSnap => {
				if (docSnap.exists()) {
					let data = docSnap.data();

					if(HIService.getTokenJWT())
						HIService.saveUrlJWT(data.url + HIService.getTokenJWT());

					if(	( 	HIService.getFlagAnsweredDB() !== null
							&& HIService.getFlagAnsweredDB() === moment().format("YYYYMMDD")	)
						// || ( 	HIService.getFlagAnsweredApp() !== null
						// 	&& HIService.getFlagAnsweredApp() !== moment().format("YYYYMMDD")	)
						){
						resolve("success");
					}else{
						HIService.saveFlagHI(moment().format("YYYYMMDD"));
						let keyQuestion = data.url.split('/saringC19/')[1];
						keyQuestion = keyQuestion.substring(0, keyQuestion.length - 1)
						ApiHIService.setHeader();
						ApiHIService.post("surveyStudent/contentQ", { keyQuestion })
							.then(({ data }) => {
								if(data == 'answered' || data == 'noSurveyActive'){
									HIService.saveFlagAnsweredDB(moment().format("YYYYMMDD"));
									HIService.saveFlagAnsweredApp(moment().format("YYYYMMDD"));
									HIService.destroyFlagHI();
								}else if(data != null && data != '' && data.batch != null){
									HIService.destroyFlagAnsweredDB();
									HIService.destroyFlagAnsweredApp();
								}else{
									// HIService.saveFlagAnsweredDB(moment().format("YYYYMMDD"));
									// HIService.destroyFlagHI();
								}
								resolve("success");
							}).catch(({ response }) => {
								reject(response);
							});
					}
				} else {
					HIService.destroyFlagHI();
					HIService.destroyFlagAnsweredApp();
					HIService.destroyFlagAnsweredDB();
					HIService.destroyUrlJWT();
					resolve("success");
				}
			});
		});
	},
  },
  mutations: {
    
  }
};