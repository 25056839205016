// import VueAxios from "vue-axios";
import axios from "axios";
import JwtService from "./jwt.integrasi.service";
import { app } from '@/main.js'
import { API_INTEGRASI_URL } from "./config";


// let instance = null;

const ApiIntegrasiService = {
  init() {
    const axiosIntegrasi = axios.create({
        baseURL: API_INTEGRASI_URL,
        mode: 'no-cors',
        // headers: {
        //   'Access-Control-Allow-Origin': '*',
        //   'Content-Type': 'application/json',
        //   'Authorization' : `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjcmVhdGVkX2J5IjoiSW50ZWdyYXNpIiwidXJsIjpbIi9tcGNhcmQiLCIvZ2FtYmFyIiwiL3NzbyIsIi9waWN0dXJlIiwiL3N0YWZmL3BvcnRhbF9sb2dpbiIsIi9zdHVkZW50L3BvcnRhbF9sb2dpbiIsIi9oYXBwaW5lc3MiXSwic2VyaWFsIjoxLCJ1c2VyIjoiaGFwcGluZXNzIiwicGVnYXdhaV9iZXJ0YW5nZ3VuZ2phd2FiIjoiQUhNQUQgU09CUkkgQklOIEFCRFVMIEhBUklTIn0.E6BrNecVzpMj7UUAI9C_qSGTrB0DosB-Ulm3LlJtg3o`, //where applicable,
        // },
        withCredentials: true,
        credentials: 'same-origin',
    });

    return axiosIntegrasi;
    
    // Vue.use(VueAxios, { axios: axiosOrg, axiosIntegrasi: axiosIntegrasiOrg });
  },

  setHeader() {
    app.axiosIntegrasi.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${JwtService.getToken()}`;
  },

  query(resource, params) {
    return app.axiosIntegrasi.get(resource, params).catch(error => {
      throw new Error(`[RWV] ApiService ${error}`);
    });
  },

  get(resource, slug = "") {
    return app.axiosIntegrasi.get(`${resource}/${slug}`).catch(error => {
      throw new Error(`[RWV] ApiService ${error}`);
    });
  },

  getParam(resource, slug = "") {
    return app.axiosIntegrasi.get(`${resource}?${slug}`).catch(error => {
      throw new Error(`[RWV] ApiService ${error}`);
    });
  },

  post(resource, params) {
    return app.axiosIntegrasi.post(`${resource}`, params);
  },

  update(resource, slug, params) {
    return app.axiosIntegrasi.put(`${resource}/${slug}`, params);
  },

  put(resource, params) {
    return app.axiosIntegrasi.put(`${resource}`, params);
  },

  delete(resource) {
    return app.axiosIntegrasi.delete(resource).catch(error => {
      throw new Error(`[RWV] ApiService ${error}`);
    });
  }
};

export default ApiIntegrasiService;

export const TagsService = {
  get() {
    return ApiIntegrasiService.get("tags");
  }
};

export const CommentsService = {
  get(slug) {
    if (typeof slug !== "string") {
      throw new Error(
        "[RWV] CommentsService.get() article slug required to fetch comments"
      );
    }
    return ApiIntegrasiService.get("articles", `${slug}/comments`);
  },

  post(slug, payload) {
    return ApiIntegrasiService.post(`articles/${slug}/comments`, {
      comment: { body: payload }
    });
  },

  destroy(slug, commentId) {
    return ApiIntegrasiService.delete(`articles/${slug}/comments/${commentId}`);
  }
};