<template>
  <div class="appBottomMenu">
    <a class="item" data-bs-target="#myModal" @click="turnCameraOn()" data-bs-toggle="modal">
      <div>
        <i class="bi bi-qr-code-scan"></i>
        <strong>Pengimbas QR</strong>
      </div>
    </a>

    <router-link to="/wifi" class="item">
      <div class="col">
        <i class="bi bi-wifi"></i>
        <strong>Wifi@UiTM</strong>
      </div>
    </router-link>
    <router-link to="/" class="item">
      <div class="col">
        <div class="action-button large">
          <i class="bi bi-house-door"></i>
        </div>
      </div>
    </router-link>
    <router-link to="/virtual_card" class="item">
      <div class="col">
        <i class="bi bi-person-badge-fill"></i>
        <strong>Kad Digital</strong>
      </div>
    </router-link>
    <router-link to="/timetable" class="item">
      <div class="col">
        <i class="bi bi-calendar-week"></i>
        <strong>Jadual Kelas</strong>
      </div>
    </router-link>
  </div>


  <div class="modal" id="myModal">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Pengimbas QR</h5>
          <button type="button" class="btn-close" ref="Close" data-bs-dismiss="modal" aria-label="Close"
            @click="turnCameraOff()"></button>
        </div>
        <div class="modal-body">
          <qrcode-stream :constraints="{ facingMode: 'environment' }" :camera="camera" @decode="onDecode" @init="onInit"
            v-if="!destroyed">
            <div class="loader loading-indicator" v-if="loading">Loading...</div>

            <div v-if="validationFailure" class="validation-failure">
              This is NOT a URL!
            </div>

            <div v-if="validationPending" class="validation-pending">
              Long validation in progress...
            </div>
          </qrcode-stream>
        </div>
      </div>
    </div>
  </div>

  <div id="toast-success" class="toast-box toast-bottom" :class="classSuccess">
    <div class="in">
      <ion-icon name="checkmark-circle" class="text-success"></ion-icon>
      <div class="text">
        {{ successMsg }}
      </div>
    </div>
    <button type="button" class="btn btn-sm btn-text-success close-button" @click="successMsg=''">CLOSE</button>
  </div>

  <div id="toast-failed" class="toast-box toast-bottom" :class="classFailed">
    <div class="in">
      <ion-icon name="close-circle" class="text-danger"></ion-icon>
      <div class="text">
        {{ failedMsg }}
      </div>
    </div>
    <button type="button" class="btn btn-sm btn-text-failedMsg close-button" @click="failedMsg=''">CLOSE</button>
  </div>
</template>
<script>
import { QrcodeStream  } from 'vue3-qrcode-reader'

export default {

components: { QrcodeStream },
  

  data () {
      
    return {
      loading: false,
      destroyed: false,
      isValid: undefined,
      camera: 'off',
      result: null,
      successMsg: null,
      failedMsg: null,
    }
  },

  computed: {
    validationPending () {
      return this.isValid === undefined
        && this.camera === 'off'
    },

    validationFailure () {
      return this.isValid === false
    },
    classSuccess: function () {
      return {
        'show': this.successMsg != null && this.successMsg != ''
      }
    },
    classFailed: function () {
      return {
        'show': this.failedMsg != null && this.failedMsg != ''
      }
    }
  },
  methods: {

    async onInit (promise) {
      this.loading = true

      try {
        await promise
      } catch (error) {
        console.error(error)
      } finally {
        this.loading = false
      }
    },

    resetValidationState () {
      this.isValid = undefined
    },

    async onDecode (content) {
      this.result = content
      this.turnCameraOff()

      // pretend it's taking really long
      await this.timeout(3000)
      this.isValid = content.startsWith('http')
      // Handle on success condition with the decoded text or result.
      if (this.isValid) {
        if(content.includes("masmed2u")){
          var url = content+"/"+this.$store.state.auth.user.studentid
          url = "program/attendance/" + url.split('attendance/')[1];

          this.$store.dispatch("program/submitAttendent", url).then((data) => {            
            if(data.succcess)
              this.successMsg = 'Congratulations on successfully completing your course!';
            else
              this.failedMsg = 'Failed to scan QR code. Please try again';
          });

          this.$refs.Close.click();
          this.camera = 'off'
          this.loading = false
          return;
          
        }else{
          window.location.href = content
        }
            
      } else {
        this.$refs.Close.click();
        this.camera = 'off'
        this.loading = false
      }

      // some more delay, so users have time to read the message
      await this.timeout(2000)

      this.turnCameraOn()
    },

    turnCameraOn () {
      this.camera = 'auto'
    },

    turnCameraOff () {
      this.camera = 'off'
    },

    timeout (ms) {
      return new Promise(resolve => {
        window.setTimeout(resolve, ms)
      })
    },

    async reload () {
      this.destroyed = true

      await this.$nextTick()

      this.destroyed = false
    }
  }
}
</script>

<style scoped>
.validation-success,
.validation-failure,
.validation-pending {
  position: absolute;
  width: 100%;
  height: 100%;

  background-color: rgba(255, 255, 255, .8);
  text-align: center;
  font-weight: bold;
  font-size: 1.4rem;
  padding: 10px;

  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
}
.validation-success {
  color: green;
}
.validation-failure {
  color: red;
}
button {
  margin-bottom: 20px;
}

.loading-indicator {
  font-weight: bold;
  font-size: 2rem;
  text-align: center;
}
</style>
